/**
 * This represents a sample test profile image while the app awaits the server
 * implementation for profile images.
 *
 * TODO: replace this with actual server images.
 *
 * @deprecated
 */
export const testProfileImage: Record<number, string> = {
  0: 'https://media-exp2.licdn.com/dms/image/C4D03AQF8D_E8zT-XEg/profile-displayphoto-shrink_200_200/0/1516586552085?e=2147483647&v=beta&t=ee724ONp3E_l9sg2W0w5pnforRmfsx82aSXLd67CbNs',
  1: 'https://media-exp2.licdn.com/dms/image/C4D03AQF8D_E8zT-XEg/profile-displayphoto-shrink_200_200/0/1516586552085?e=2147483647&v=beta&t=ee724ONp3E_l9sg2W0w5pnforRmfsx82aSXLd67CbNs',
  2: 'https://media-exp1.licdn.com/dms/image/C5603AQGxyC0L3A8JEg/profile-displayphoto-shrink_800_800/0/1516278960223?e=2147483647&v=beta&t=a_Pv1Ef0qMOunFz5u9XzY443PmB70FydQR5R4jQ7BCo',
};
