import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * Creates wrapper styles for the containing `div` around the check field and
 * its label.
 */
export const CheckInputContainer = styled.div`
  ${tw`flex items-start`};

  .check-wrapper {
    ${tw`flex h-5 items-center`};
  }

  .label-wrapper {
    ${tw`mx-3 text-sm`};
  }
`;
